import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

import Layout from '../components/Layout'
import { NewsSubscription } from '../components/Sections'
import { Content, ArrowLink } from '../components/Atoms'
import { labels, localeUrls } from '../langs/langs'

import calendarI from '../images/calendar-big.svg'

const NewsCard = ({ localeID, item }) => {
  const gatsbyImageData = item?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  return (
    <div>
      <div
        className={`imgCon16 bg-blue ${classNames({
          'flex items-center justify-center': !gatsbyImageData
        })}`}
      >
        {!!gatsbyImageData ? (
          <GatsbyImage className="!absolute inset-0" image={gatsbyImageData} alt="test" />
        ) : (
          <img className="absolute inset-0 m-auto" src={calendarI} />
        )}
      </div>
      <p className="pt-6">
        {item.date} | <strong>{item.readingtimes.readingtime} min read</strong>
      </p>
      <h3 className="py-3 font-bold text-2xl">{item.title}</h3>
      <ArrowLink to={`${localeUrls[localeID]['NEWS']}/${item.slug}`} className="text-blue">
        {labels[localeID]['READ']}
      </ArrowLink>
    </div>
  )
}

const NewsArchivePage = ({ location, data, pageContext: { localeID } }) => {
  const posts = data.allWpPost.nodes
  return (
    <Layout location={location} title={labels[localeID]['NEWS']} localeID={localeID}>
      <div className="py-24 sm:py-32">
        <Content>
          <h1 className="text-5xl font-bold">{labels[localeID]['NEWS']}</h1>
          <p className="py-8 font-medium">
            {labels[localeID]['NEWS_C']}
            <br />
            {labels[localeID]['FOOTER_TIPS'].replace(/<\/?[^>]+(>|$)/g, '')}
          </p>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-8 gap-y-16 pt-6">
            {!!posts.length
              ? posts.map((item, index) => {
                  return <NewsCard key={index} item={item} localeID={localeID} />
                })
              : ''}
          </div>
        </Content>
      </div>
      <NewsSubscription localeID={localeID} />
    </Layout>
  )
}

export default NewsArchivePage

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!, $localeID: ID) {
    allWpPost(
      filter: { locale: { id: { eq: $localeID } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        title
        date(formatString: "DD / MM / YYYY")
        slug
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
        readingtimes {
          readingtime
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`
